export const BLOG_DATA = {
  en: [
    {
      text: 'Backpackers Guide To South East Asia',
      link: 'https://www.hostelworld.com/blog/backpacking-southeast-asia/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_360/f_auto,q_auto,w_360/v1707833880/pwa/blog/backpacking-S-Asia.jpg',
      target: '_blank',
    },
    {
      text: 'How To Backpack Around Laos',
      link: 'https://www.hostelworld.com/blog/backpacking-laos/',
      image: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_360/v1707833880/pwa/blog/backpacking-laos-thumb.jpg',
      target: '_blank',
    },
    {
      text: 'Backpacker\'s Guide To Travel Around Vietnam',
      link: 'https://www.hostelworld.com/blog/backpacking-vietnam/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_360/v1707833881/pwa/blog/backpacking-vietnam-thumb.jpg',
      target: '_blank',
    },
    {
      text: 'Complete Guide To Backpacking Europe',
      link: 'https://www.hostelworld.com/blog/backpacking-europe/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_360/v1707833880/pwa/blog/backpacking-europe-thumb-image.jpg',
      target: '_blank',
    },
    {
      text: 'This Year\'s Best Interrail Destinations',
      link: 'https://www.hostelworld.com/blog/top-destinations-for-inter-railing/',
      image: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_360/v1707833881/pwa/blog/interrail-thumb.jpg',
      target: '_blank',
    },
    {
      text: 'Solo Travel In Spain',
      link: 'https://www.hostelworld.com/blog/solo-travel/spain/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_360/v1707833881/pwa/blog/solo-travel-in-spain-thumb.jpg',
      target: '_blank',
    },
    {
      text: 'Solo Traveller\'s Guide To Backpacking The USA',
      link: 'https://www.hostelworld.com/blog/backpacking-usa/',
      image: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_360/v1707833879/pwa/blog/backpacking-usa-thumb.jpg',
      target: '_blank',
    },
    {
      text: 'What To Know When Backpacking Australia',
      link: 'https://www.hostelworld.com/blog/backpacking-australia/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_360/v1707833880/pwa/blog/backpacking-Australia-thumb.jpg',
      target: '_blank',
    },
  ],
  de: [
    {
      text: 'HOSCARs 2022: Die außergewöhnlichsten Herbergen der Welt wurden enthüllt!',
      link: 'https://www.hostelworld.com/hoscars',
      image: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1652428355/blog/hw/hoscars2022-small-image.jpg',
      target: '_blank',
    },
    {
      text: 'Trendsetter aufgepasst: 20 unentdeckte Reiseziele für deine Bucket-Liste',
      link: 'https://www.german.hostelworld.com/blog/unentdeckte-reiseziele',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/2-de-undiscovered.png',
      target: '_blank',
    },
    {
      text: 'OOO La La! Die besten Stadtteile in Paris für den perfekten Städtetrip',
      link: 'https://www.german.hostelworld.com/blog/beste-stadtteile-paris/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/3-de-paris.png',
      target: '_blank',
    },
    {
      text: 'Ein bunter Schatz im Orient: Deine unglaubliche Reise durch Marokko',
      link: 'https://www.german.hostelworld.com/blog/marokko-sehenswuerdigkeiten/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/4-de-marrakech.png',
      target: '_blank',
    },
    {
      text: 'Die 19 aufregensten Orte in Australien, die du unbedingt sehen musst',
      link: 'https://www.german.hostelworld.com/blog/beste-orte-australien/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/5-de-australia.png',
      target: '_blank',
    },
    {
      text: 'Lissabon wie ein Local: Entdecke die coolsten Viertel der portugiesischen Hauptstadt',
      link: 'https://www.german.hostelworld.com/blog/lissabon-stadtteile/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/6-de-lisbon.png',
      target: '_blank',
    },
    {
      text: 'Backpacking-Guide Indonesien: Blogger verraten alles, was du für dein Abenteuer wissen musst',
      link: 'https://www.german.hostelworld.com/blog/backpacking-guide-indonesien/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/7-de-indonesia.png',
      target: '_blank',
    },
  ],
  it: [
    {
      text: 'HOSCARs 2022 - Sono stati svelati gli ostelli più straordinari del mondo!',
      link: 'https://www.hostelworld.com/hoscars',
      image: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1652428355/blog/hw/hoscars2022-small-image.jpg',
      target: '_blank',
    },
    {
      text: 'No non siete morti... Siete solo alle Fiji!',
      link: 'https://www.italian.hostelworld.com/blog/fiji-cosa-vedere',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/2-it-fiji.png',
      target: '_blank',
    },
    {
      text: 'London\'s Calling - Ecco come visitare Londra come un londinese',
      link: 'https://www.italian.hostelworld.com/blog/cosa-vedere-a-londra/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/3-it-london.png',
      target: '_blank',
    },
    {
      text: 'Fra colori ed esperienze: ecco cosa non perdere a Lisbona',
      link: 'https://www.italian.hostelworld.com/blog/lisbona-cosa-vedere/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/4-it-lisbon.png',
      target: '_blank',
    },
    {
      text: 'Uno degli arcipelaghi più belli del mondo: scopri l\'Indonesia',
      link: 'https://www.italian.hostelworld.com/blog/indonesia-cosa-vedere/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/5-it-indonesia.png',
      target: '_blank',
    },
    {
      text: '20 in viaggio – Come è cambiato il backpacking negli ultimi 20 anni',
      link: 'https://www.italian.hostelworld.com/blog/come-e-cambiato-il-backpacking-negli-ultimi-20-anni/?lang=it',
      image: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/blog/hw/13_03_20/6-it-backpacking.jpg',
      target: '_blank',
    },
    {
      text: 'Un viaggio dalle mille e una notte: road trip in Marocco',
      link: 'https://www.italian.hostelworld.com/blog/marocco-cosa-vedere/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/7-it-morocco.png',
      target: '_blank',
    },
  ],
  br: [
    {
      text: 'HOSCARs 2022 - Os hostels mais extraordinários do mundo foram revelados!',
      link: 'https://www.hostelworld.com/hoscars',
      image: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1652428355/blog/hw/hoscars2022-small-image.jpg',
      target: '_blank',
    },
    {
      text: '5 paisagens que vão convencer você a visitar Uyuni nas próximas férias',
      link: 'https://www.brazilian.hostelworld.com/blog/5-paisagens-que-vao-convencer-voce-a-visitar-uyuni-nas-proximas-ferias',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/2-br-bolivia.png',
      target: '_blank',
    },
    {
      text: 'Para fugir do mundo: 9 hostels “no meio do nada” no Brasil',
      link: 'https://www.brazilian.hostelworld.com/blog/hostels-no-meio-do-nada-no-brasil',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/3-br-brasil.png',
      target: '_blank',
    },
    {
      text: 'O que fazer em Cartagena: as melhores dicas para aproveitar a cidade como um mochileiro',
      link: 'https://www.brazilian.hostelworld.com/blog/o-que-fazer-em-cartagena-as-melhores-dicas-para-aproveitar-a-cidade',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/4-br-cartagena.png',
      target: '_blank',
    },
    {
      text: 'O que fazer em San Andres',
      link: 'https://www.brazilian.hostelworld.com/blog/o-que-fazer-em-san-andres',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/5-br-san_andres.png',
      target: '_blank',
    },
    {
      text: 'Os Melhores Lugares Para Visitar No Centro-Oeste',
      link: 'https://www.brazilian.hostelworld.com/blog/os-melhores-lugares-para-visitar-no-centro-oeste',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/6-br-brasil.png',
      target: '_blank',
    },
    {
      text: 'Peru muito além de Machu Picchu',
      link: 'https://www.brazilian.hostelworld.com/blog/guia-peru',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/7-br-peru.png',
      target: '_blank',
    },
  ],
  fr: [
    {
      text: 'HOSCARs 2022 - Les auberges les plus extraordinaires du monde ont été révélées!',
      link: 'https://www.hostelworld.com/hoscars',
      image: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1652428355/blog/hw/hoscars2022-small-image.jpg',
      target: '_blank',
    },
    {
      text: 'Les 15 endroits à voir au moins une fois avant qu\'ils ne disparaissent',
      link: 'https://www.french.hostelworld.com/blog/endroits-a-visiter-avant-qu-ils-ne-disparaissent',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/2-fr-undiscovered.png',
      target: '_blank',
    },
    {
      text: 'De Marrakech à Merzouga : road trip de 5 jours au Maroc',
      link: 'https://www.french.hostelworld.com/blog/road-trip-maroc',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/3-fr-marrakech.png',
      target: '_blank',
    },
    {
      text: 'Psst... Voici les bons plans pour découvrir Tel Aviv',
      link: 'https://www.french.hostelworld.com/blog/que-faire-a-tel-aviv',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/4-fr-telaviv.png',
      target: '_blank',
    },
    {
      text: 'Psst… On vous emmène dans les coins les plus insolites de Londres',
      link: 'https://www.french.hostelworld.com/blog/londres-insolite/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/5-fr-londres.png',
      target: '_blank',
    },
    {
      text: '12 raisons pour lesquelles Lisbonne est THE place to be !',
      link: 'https://www.french.hostelworld.com/blog/12-raisons-de-visiter-lisbonne',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/6-fr-lisbon-city.png',
      target: '_blank',
    },
    {
      text: 'Spritz, cicchetti, labyrinthe de ruelles… Voici les secrets bien gardés de Venise',
      link: 'https://www.french.hostelworld.com/blog/que-faire-a-venise/',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/7-fr-venise.png',
      target: '_blank',
    },
  ],
  es: [
    {
      text: 'HOSCARs 2022 - ¡Se han revelado los albergues más extraordinarios del mundo!',
      link: 'https://www.hostelworld.com/hoscars',
      image: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1652428355/blog/hw/hoscars2022-small-image.jpg',
      target: '_blank',
    },
    {
      text: '22 hostels sostenibles para viajeros responsables 🌿',
      link: 'https://www.spanish.hostelworld.com/blog/hostels-sostenibles',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/2-es-green_hostels.png',
      target: '_blank',
    },
    {
      text: 'Overtourism: cuando los turistas destruyen el paraíso (y cómo no ser uno de ellos)',
      link: 'https://www.spanish.hostelworld.com/blog/overtourism/?lang=es',
      image: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/blog/hw/13_03_20/3-es-overtourism.jpg',
      target: '_blank',
    },
    {
      text: 'Salir a explorar una ciudad nueva sin tu smartphone, ¿te atreves?',
      link: 'https://www.spanish.hostelworld.com/blog/explorar-una-ciudad-sin-smartphone',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/4-es-smartphone.png',
      target: '_blank',
    },
    {
      text: 'Guía de comida vegana para mochileros',
      link: 'https://www.spanish.hostelworld.com/blog/guia-de-comida-vegana-para-mochileros',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/5-es-vegan.png',
      target: '_blank',
    },
    {
      text: '19 pueblos de colores alrededor del mundo',
      link: 'https://www.spanish.hostelworld.com/blog/pueblos-de-colores/?lang=es',
      image: 'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/blog/hw/13_03_20/6-es-capitola.jpg',
      target: '_blank',
    },
    {
      text: '10 hostels con mascotas que despertarán tu instinto viajero',
      link: 'https://www.spanish.hostelworld.com/blog/hostels-con-mascotas',
      image:
        'https://a.hwstatic.com/image/upload/f_auto,q_auto,w_224/v1561618538/hw/homepage/blog-panels/7-es-pets.png',
      target: '_blank',
    },
  ],
};
