<script setup>
const props = defineProps({
  img: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: '',
  },
  target: {
    type: String,
    default: '',
  },
});

const { $tracking } = useNuxtApp();

// ### TRACKING
function onClicked(text) {
  const trackingInfo = {
    segment: {
      event_name: 'Homepage Event',
      action: 'Blog Panel Clicked',
      article_clicked: text,
    },
    gtm: { parameter1: text },
  };
  $tracking.onBlogItemInteraction(trackingInfo.gtm, trackingInfo.segment);
}
</script>

<template>
  <a
    :target="target"
    :href="props.link"
    rel="noopener noreferrer"
    :title="props.text"
    @click="onClicked(text)"
  >
    <div class="card-container">
      <div class="card-img">
        <img
          :src="props.img"
          loading="lazy"
          :alt="`${$t('t_RWDVIEW')}: ${props.text}`"
          width="211"
          height="120"
        />
      </div>
      <div class="card-text">{{ text }}</div>
    </div>
  </a>
</template>

<style lang="scss" scoped>
.card-container {
  display: flex;
  flex-direction: column;
  padding: $wds-spacing-s $wds-spacing-s $wds-spacing-m;
  box-shadow: inset 0 0 0 wds-rem(1px) $wds-color-ink-lighter;
  border-radius: $wds-border_radius-xl;
  width: wds-rem(227px);
  height: wds-rem(224px);

  &:hover {
    box-shadow: $wds-shadow-light_m;
  }

  &:focus-within,
  &:focus,
  &:focus-visible,
  &:active {
    box-shadow:
      inset 0 0 0 wds-rem(1px) $wds-color-ink-lighter,
      $wds-color-white 0 0 0 $wds-spacing-xxs,
      $wds-color-black 0 0 0 $wds-spacing-xs;
  }

  & .card-img {
    width: wds-rem(211px);
    height: wds-rem(120px);
    border-radius: $wds-border_radius-m;
    overflow: hidden;

    & img {
      width: 100%;
    }
  }

  & .card-text {
    @include title-6-bld;

    color: $wds-color-ink-darker;
    margin: auto $wds-spacing-s;
  }
}

a {
  text-decoration: none;
}

@include tablet {
  .card-container {
    width: wds-rem(240px);

    & .card-text {
      @include body-1-bld;

      margin: auto wds-rem(14.5px);
    }

    & .card-img {
      width: wds-rem(224px);
    }
  }
}
</style>
